import * as React from "react";
import Head from "next/head";
import Image from "next/image";

// Import required MUI layout/components
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuUnstyled from "@mui/base/MenuUnstyled";
import div from "@mui/base/MenuItemUnstyled";
import Link from "@mui/material/Link";

import mainTheme from "./mainTheme";
import navStyles from "../styles/Navigation.module.css";

const MainHeader = () => {
  const [activeMenu, setActiveMenu] = React.useState(null);
  const handleOpen = (event) => {
    console.log(event.target.id);
    setActiveMenu(event.target.id);
  };
  const handleClose = () => {
    setActiveMenu(null);
  };

  return (
    <ThemeProvider theme={mainTheme}>
    </ThemeProvider>
  );
};

export default MainHeader;
